@import "~bootstrap/scss/bootstrap";

#bg-img {
  min-height: 400px;
  background-image: url('../images/bg-img.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.o-8 {
  opacity: 0.8;
}
